<template>
    <v-container style="max-width: 1200px;">
        <h1>
            {{this.$parent.$parent.$parent.language.joinUs}}
        </h1>
        <v-sheet
            class="mt-3"
            elevation="1"
            rounded="lg"
        >
            <v-row>
                <v-col 
                    class="text-center mr-8 ml-8"
				>
					<p>
						{{this.$parent.$parent.$parent.language.joinUsDescription}}
					</p>
                </v-col>
            </v-row>
			<v-row>
				<v-col cols="12" md="6" class="ml-4">
					<div v-if="!$vuetify.breakpoint.mobile" class="mapouter"><div class="gmap_canvas"><iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=departamento%20de%20ingenieria%20informatica%20usach&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://123movies-to.org">123movies</a><br><a href="https://www.embedgooglemap.net">embed google map to website</a></div></div>
					<div v-else class="mapouter300"><div class="gmap_canvas300"><iframe width="300" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=departamento%20de%20ingenieria%20informatica%20usach&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://123movies-to.org">123movies</a><br><a href="https://www.embedgooglemap.net">embed google map to website</a></div></div>
				</v-col>
				<v-col class="text-center">
                    <v-icon
                        x-large
                        color="primary"
						class="mt-12"
                    >
                        mdi-map-marker
                    </v-icon>
                    <p>{{info.location}}</p>
                    <v-icon
                        x-large
                        color="primary"
						class="mt-12"
                    >
                        mdi-phone
                    </v-icon>
                    <p>{{info.phone}}</p>
                    <v-icon
                        x-large
                        color="primary"
						class="mt-12"
                    >
                        mdi-email
                    </v-icon>
                    <p>{{info.mail}}</p>					
				</v-col>
			</v-row>
			<v-row class="mr-8 ml-8 mt-4 mb-4">
                <v-col
                    cols="12"
                    sm="12"
                    lg="4"
                    class="text-center"
                >
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    lg="4"
                    class="text-center"
                >
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    lg="4"
                    class="text-center"
                >
                </v-col>
            </v-row>
        </v-sheet>
    </v-container>
</template>
<script>
export default {

    data: () => ({
        info: require('../assets/info.json'),
    }),
}
</script>

<style scoped>
.mapouter{
    position:relative;
    text-align:right;
    height:500px;
    width:600px;
}
.gmap_canvas {
    overflow:hidden;
    background:none!important;
    height:500px;
    width:600px;
}
.mapouter300{
    position:relative;
    text-align:right;
    height:500px;
    width:300px;
}
.gmap_canvas300 {
    overflow:hidden;
    background:none!important;
    height:500px;
    width:300px;
}
</style>
