<template>
  <v-card
    height="500"
    light
  >
    <v-img
      v-if="card.image"
      :src="card.image"
      :height="$vuetify.breakpoint.mobile ? 225 : 300"
      width="1200"
    ></v-img>
    <v-img
      v-else
      src="@/assets/default.jpg"
      :height="300"
      width="1200"
    ></v-img>

    <v-card-title>
      {{card.title}}
    </v-card-title>

    <v-card-subtitle>
      {{formatDate(card.date)}}
    </v-card-subtitle>
    <v-btn
      :to="{ name: 'Activities', hash: '#' + card._id }"
      color="primary"
    >
      {{this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.language.learn}}
    </v-btn>
  </v-card>
</template>
<script>

export default {
    name: 'CarouselActivity',
    props: ['card'],
    data: () => ({
      show: false,
    }),
    methods: {
        formatDate(date){
          var d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();

          if (month.length < 2) 
              month = '0' + month;
          if (day.length < 2) 
              day = '0' + day;

          return [year, month, day].join('-');
      }
    },
    computed: {
      validURL: function() {
        var pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
        return !!pattern.test(this.card.link);
      }
    }
}
</script>

<style scoped>
.v-card__title{
    text-align: center;
    justify-content: center;
    align-content: center;
    word-break: normal;
}
</style>