<template>
    <v-col 
    lg="6" cols="12">

    <v-card
        class="ma-3 mb-2"
    >
        <v-img
        min-height="300"
        max-height="300"
        v-if="project.image"
        :src="project.image"
        ></v-img>
        <v-img
        min-height="300"
        max-height="300"
        v-else
        src="@/assets/default.jpg"
        ></v-img>

        <v-card-title
        >
        <a :href="project.link"
            v-if="project.link"
            target="_blank">
            {{project.name}}
        </a>
        <span v-if="!project.link">{{project.name}}</span>
        </v-card-title>
        <v-card-subtitle>
            {{project.yearEnd ? 
            `${project.yearInit} - ${project.yearEnd}`
             : `${project.yearInit} - Present`}}
        </v-card-subtitle>
        <v-card-actions>
            <v-btn
                @click="show = !show"
                text
            >
                {{this.$parent.$parent.$parent.$parent.language.about}}
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                @click="showStaff = !showStaff"
                text
            >
                {{this.$parent.$parent.$parent.$parent.language.staff}}
                <v-icon>{{ showStaff ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>            
        </v-card-actions>
        <v-expand-transition>
            <div v-show="show">
                <v-divider></v-divider>
                <v-card-text>
                {{project.description}}
                </v-card-text>
                <v-spacer></v-spacer>
                <v-row class="text-center">
                    <v-col cols="6">
                        <v-card-text>
                            <strong>
                                <span class="url" @click="goToPublications(project._id)">
                                    {{this.$parent.$parent.$parent.$parent.language.publications}}
                                </span>
                            </strong>
                        </v-card-text>
                    </v-col>                    
                    <v-col cols="6">
                        <v-card-text v-if="project.link">
                            <strong>
                                <a :href="project.link"
                                    target="_blank">
                                    {{this.$parent.$parent.$parent.$parent.language.learn}}
                                </a>
                            </strong>
                        </v-card-text>
                    </v-col>
                </v-row>
            </div>  
        </v-expand-transition>
        <v-expand-transition>
            <div v-show="showStaff">
                <v-divider></v-divider>
				<v-list-item-avatar color="grey darken-3" v-for="member in members" :key="member._id" v-bind:class="[ member.active ? '' : 'grayScale' ]" class="ma-2" height="50" width="50">
					<v-tooltip bottom open-delay="200" color="black">
						<template v-slot:activator="{ on, attrs }">
							<v-img
								class="elevation-6"
								v-if="member.image"
								:src="member.image"
								v-bind="attrs"
								v-on="on"								
								></v-img>
								<v-img
								v-if="!member.image"
								class="elevation-6"
								src="@/assets/account.png"
								v-bind="attrs"
								v-on="on"
							></v-img>
						</template>
						<div class="text-center">
							<span>{{member.fullName}}</span>
							<v-divider dark></v-divider>
						</div>	
					</v-tooltip>
				</v-list-item-avatar>
            </div>                  
        </v-expand-transition>
    </v-card>
    </v-col>
</template>
<script>
import axios from 'axios';

export default {
    name: 'Project',
    props: ['project'],
    data: () => ({
        baseURL: '',
		show: false,
		showStaff: false,
		members: []
    }),
    mounted(){
        axios
        .get(`${process.env.VUE_APP_API_URL}/member/byProject/` + this.project._id)
        .then(res => {
            this.members = res.data.data;
            this.members.sort((a, b) => b.active - a.active)
			this.members = this.members.map( r => {
				return {
					...r,
					image: r.image ? '' + Buffer.from(r.image) : null,
					startDate: Date.parse(r.startDate),
					endDate: r.endDate ? Date.parse(r.endDate) : null
				}
            })			
        })
        .catch(err => {
            console.error("axios err", err);
        });

        this.baseURL = window.location.origin;
    },
    methods:{
        goToPublications(projectId){
            this.$router.push({ path: '/publications', hash: '#' + projectId });
        }
    },
    watch: {
        show: function(){
            if(this.show){
                this.showStaff = false;
            }
        },
        showStaff: function(){
            if(this.showStaff){
                this.show = false;
            }
        }
    }
}
</script>

<style scoped>
.v-card__title{
  word-break: break-word;
}
.url{
    color: #1976d2;
    text-decoration: underline;
    cursor: pointer;
}
.grayScale{
    filter: grayscale(1);
}
</style>